<template>
    <div class="research-landing">
        <div class="hero page-heading">
            <HeroBasic
                :heading="title"
                :richText="dek"
                accentColor="grey"
                :showTopographic="true"
                :hasHeaderOffset="true"
                :pageLanguage="pageLanguage"
            />
        </div>

        <div v-if="researchHeroContentFeedItems.length" class="section-wrapper featured">
            <ContentSliderBlock :contentCards="researchHeroContentFeedItems" variant="contentOnly" />
        </div>

        <div v-if="hasRecentArticlesAndPapers" class="section-wrapper articles">
            <div class="section heading">
                <Eyebrow :hasUnderline="true" class="eyebrow">{{
                    $t('recent articles & papers', 1, {
                        locale: pageLanguage,
                    })
                }}</Eyebrow>
            </div>
            <div class="section">
                <ContentFeedBlock :contentFeed="recentArticlesAndPapers" :pageLanguage="pageLanguage" size="100" />
            </div>
        </div>

        <div v-if="hasRecentlyPublished && GTMCenter.GTMCenter === 'CEIP'" class="section-wrapper publications">
            <div class="section heading">
                <Eyebrow :hasUnderline="true" class="eyebrow">Featured Research</Eyebrow>
            </div>
            <div class="section">
                <ContentFeedBlock :contentFeed="recentlyPublished" :pageLanguage="pageLanguage" size="100" />
                <Button url="/featured-research" variant="secondary"> See More Featured Research </Button>
            </div>
        </div>

        <SubscribeBlock
            v-if="newsletter.newsletter?.title"
            theme="dark"
            v-bind="newsletter.newsletter"
            :title="newsletter.newsletter?.title"
            :pageLanguage="pageLanguage"
            newsletterFormName="BasicForm"
            size="full"
        />

        <IndexTier
            :title="`${$t('AllResearch', 1, { locale: pageLanguage })}`"
            :contentType="contentType"
            :renderedFilters="renderedFilters"
            :pageLanguage="pageLanguage"
            :pageId="id"
        >
            <template #results="{ results }">
                <ContentCardBlock
                    v-for="result in results"
                    :key="result.id"
                    :related="result"
                    orientation="wide"
                    :pageLanguage="pageLanguage"
                    context="inline"
                    class="result"
                    :hideDateCard="true"
                    :showPublicationDate="true"
                />
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
import { provide } from 'vue';
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    title: {
        type: String,
    },
    dek: {
        type: Object,
    },
    id: {
        type: String,
    },
    contentType: {
        type: String,
        default: '',
    },
    accentColor: {
        type: String,
    },
    researchHeroContent: {
        type: Object,
    },
    recentArticlesAndPapers: {
        type: Object,
    },
    recentlyPublished: {
        type: Object,
    },
    newsletter: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const renderedFilters = ['topics', 'regions', 'years', 'experts'];

const researchHeroContentFeedItems = computed(() =>
    getContentFeedItems(props.researchHeroContent?.feed, props.researchHeroContent?.dynamic),
);

const hasRecentArticlesAndPapers = computed(
    () => getContentFeedItems(props.recentArticlesAndPapers?.feed, props.recentArticlesAndPapers?.dynamic).length,
);

const hasRecentlyPublished = computed(
    () => getContentFeedItems(props.recentlyPublished?.feed, props.recentlyPublished?.dynamic).length,
);

provide('colorOverride', props.accentColor);

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Research Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.page-heading {
    overflow: hidden;
    position: relative;
    background: color(grey, light);
    border-bottom: 0.1rem solid palette(border-light);
}

.section-wrapper {
    &:not(.featured) {
        @include padding-tb(vertical-space(4));
    }

    &.articles {
        background: color(grey, light);
    }
}

.section {
    @include content-section;

    .eyebrow {
        margin-inline-start: var(--col-gutter);
    }
}
</style>
